* {
  font-family: "Inter", sans-serif;
}
input::placeholder, textarea::placeholder, select::placeholder {
  color: #637381;
}
input, select, textarea {
  outline: none!important;
}

body::-webkit-scrollbar, .customScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
body::-webkit-scrollbar-track, .customScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
body::-webkit-scrollbar-thumb, .customScroll::-webkit-scrollbar-thumb {
  background-color: rgb(200,200,200);
  outline: none;
}

.inpEffect:hover, input:focus {
  border: 1px solid #D6BBFB!important;
  box-shadow: 0px 0px 0px 4px #F4EBFF!important;
  transition: 300ms ease-in-out!important;
}